<template>
	<div>
		<div class="ft20 cl-main ftw600">业绩填单类型</div>
		<div class="mt20">
			<div class="pd30 bg-w" style="min-height: 800px;">
				<div>
					<a-button type="primary" icon="plus" @click="addPerformanceTypeAct()">添加分类</a-button>
				</div>

				<div class="mt20">
					<div class="wxb-table-gray">
						<a-table rowKey="performance_type_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
							:data-source="datas" :loading="loading">

							<div class="flex alcenter center" slot="single_commission" slot-scope="single_commission,record">
								¥{{single_commission}}
							</div>

							<template slot="action" slot-scope="record,index">
								<div class="flex center">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											<a-menu-item>
												<a class="menu-act" href="javascript:;" @click="editPerformanceTypeAct(record)">
													<i class="iconfont ft14 iconedit"></i>
													<span class="ml10">编辑分类</span>
												</a>
											</a-menu-item>
											<a-menu-item>
												<a class="menu-act" href="javascript:;" @click="delAct(record)">
													<i class="iconfont ft14 icondelete"></i>
													<span class="ml10">删除分类</span>
												</a>
											</a-menu-item>
										</a-menu>
									</a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				</div>

				<div v-if="editPerformanceTypeVisible">
					<edit-performance-type :visible="editPerformanceTypeVisible" :performance_type_id="performance_type_id"
					@cancel="cancelEditPerformanceType"@ok="okEditPerformanceType"></edit-performance-type>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../../common/mixin/list.js';
	import editPerformanceType from './components/PerformanceType/modal/editPerformanceType.vue';
	export default {
		mixins: [listMixin],
		components:{
			editPerformanceType
		},
		data() {
			return {
				loading: false,
				editPerformanceTypeVisible:false,
				performance_type_id:0,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: '分类名称',dataIndex: 'name',align: 'center',ellipsis: true},
					{title: '单次业绩提成',dataIndex: 'single_commission',align: 'center',scopedSlots: {customRender: 'single_commission'}},
					{title: '添加事件',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		methods: {
			getLists() {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/getPerformanceTypeList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},

			addPerformanceTypeAct(){
				this.performance_type_id=0;
				this.editPerformanceTypeVisible=true;
			},
			editPerformanceTypeAct(record){
				this.performance_type_id=record.performance_type_id;
				this.editPerformanceTypeVisible=true;
			},
			cancelEditPerformanceType(){
				this.editPerformanceTypeVisible=false;
			},
			okEditPerformanceType(){
				this.editPerformanceTypeVisible=false;
				this.getLists();
			},

			delAct(record){
				this.$confirm({
					title:'确认删除这个分类吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('platform/delPerformanceType',{
								performance_type_id:record.performance_type_id,
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},


			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
</style>
